import React, { useState, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ReCAPTCHA from 'react-google-recaptcha';
import SEO from '../components/seo';
import Layout from '../components/borden/layout';

const contactIframe = css`
  border: none;
  width: 100%;
  height: 700px;
`;

const ResponsiveIframe = styled.div`
  overflow: hidden;
  // 16:9 aspect ratio
  padding-top: 56.25%;
  position: relative;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const bordenHeading = css`
  background-color: #780032;
  color: whitesmoke;
  line-height: 44px;
  margin: 0;
  padding-left: 10px;
  padding-top: 10px;
`;

const monoSpace = css`
  font-family: 'Roboto Mono', monospace;
  padding-left: 2rem;
  padding-top: 2rem;
`;

export default function BordenContact() {
  const recaptchaRef = useRef(null);

  //STATE
  const [stateName, setStateName] = useState('');
  const [validName, setValidName] = useState(false);
  const [stateComment, setStateComment] = useState('');
  const [validComment, setValidComment] = useState(false);
  const [stateEmail, setStateEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [validReCAPTCHA, setValidReCAPTCHA] = useState(false);
  const [cannotSubmit, setCannotSubmit] = useState(true);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    validateForm();
  });

  //FUNCTIONS
  const validateForm = () => {
    setValidName(!!stateName);
    setValidComment(!!stateComment);
    setValidEmail(
      stateEmail.search(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) != -1
    );
    setCannotSubmit(!(validName && validComment && validEmail && validReCAPTCHA));
  };

  const handleFormFields = e => {
    switch (e.target.id) {
      case 'editName':
        setStateName(e.target.value);
        break;
      case 'editComment':
        setStateComment(e.target.value);
        break;
      case 'editEmail':
        setStateEmail(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleReCAPTCHA = value => {
    setValidReCAPTCHA(!!value);
  };

  const handleSubmit = () => {
    //console.log(stateName, stateComment, stateEmail);
    let theLink = document.getElementById('myMailTo');
    theLink ? (theLink.remove()) : null;
    var MailList = "usarmy.jbsa.medcom-ameddcs.list.borden-institute-information@mail.mil";
    var NewSubject = "Borden Institute Contact Form";
    var userComment = stateComment.replace(/\n/g, "%0d%0a");
    userComment = userComment.replace(/"/g, "%22");
    userComment = userComment.replace(/'/g, "%27");
    var NewBody = `The following message is from the MEDCoE Borden Contact Form:
  
  Name: ${stateName}

  Comment: ${userComment}

  Email: ${stateEmail}`

    NewBody = NewBody.replace(/\n/g, "%0d%0a");
    NewBody = NewBody.replace(/"/g, "%22");
    NewBody = NewBody.replace(/'/g, "%27");

    const newLink = document.createElement('a');
    let newLinkID = document.createAttribute('id');
    newLinkID.value = 'myMailTo';
    let newLinkHref = document.createAttribute('href');
    newLinkHref.value = `mailto:${MailList}?subject=${NewSubject}&body=${NewBody}`;
    newLink.setAttributeNode(newLinkID);
    newLink.setAttributeNode(newLinkHref);
    document.getElementById('btnReset').after(newLink);

    document.getElementById('myMailTo').click();

    setSubmitSuccess(true);
    setTimeout(() => {
      setSubmitSuccess(false);
    }, 5000);
    resetForm();
  };

  const resetForm = a => {
    setStateName('');
    setValidName(false);
    setStateComment('');
    setValidComment(false);
    setStateEmail('');
    setValidEmail(false);
    setValidReCAPTCHA(false);
    recaptchaRef.current.reset();
    setCannotSubmit(true);
  };

  return (
    <Layout>
      <SEO title='Borden Institute' />
      {/*       <div className='grid-container'>
        <ResponsiveIframe>
          <iframe
            title='Borden Contact'
            css={contactIframe}
            src='https://ke.army.mil/medcoe/BordenContact.aspx'
          />
        </ResponsiveIframe>
      </div> */}

      <div className='grid-container'>
        <h2 css={bordenHeading}>Borden Contact Form</h2>
        <div className='grid-row'>
          <div className='grid-col-12'>
            {submitSuccess ? (
              <div className='usa-alert usa-alert--success'>
                <div className='usa-alert__body'>
                  <h3 className='usa-alert__heading'>Please click send in your email client.</h3>
                  <p className='usa-alert__text'>Thank you for your comment.</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className='grid-row'>
          <div className='grid-col-6'>
            <form>
              <label htmlFor='editName' className='usa-label'>
                Name:
                <input
                  id='editName'
                  type='text'
                  className='usa-input'
                  placeholder='Enter your name'
                  title='Enter your name'
                  value={stateName}
                  onChange={handleFormFields}
                />
              </label>
              <label htmlFor='editComment' className='usa-label'>
                Comment:
                <textarea
                  id='editComment'
                  type='textarea'
                  className='usa-textarea'
                  placeholder='Enter your comment'
                  title='Enter your comment'
                  value={stateComment}
                  onChange={handleFormFields}
                />
              </label>
              <label htmlFor='editEmail' className='usa-label'>
                Your Email Address:
                <input
                  id='editEmail'
                  type='email'
                  className='usa-input'
                  pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
                  placeholder='Enter your email address'
                  title='Enter your email address'
                  value={stateEmail}
                  onChange={handleFormFields}
                />
              </label>
              <p />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey='6LdE3_0UAAAAAHFzJdGTsBvRcrUc5GXHq1Tx7RJD'
                onChange={handleReCAPTCHA}
              />
              <p />
              <button
                id='btnSubmit'
                className='usa-button'
                type='button'
                disabled={cannotSubmit}
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                id='btnReset' className='usa-button' type='button' onClick={resetForm}>
                Reset
              </button>
            </form>
          </div>
          <div css={monoSpace} className='grid-col-6'>
            <p>Using Photographs from Borden Institute Books</p>
            <p>
              Most of the photographs in our books are government photos and in the public
              domain. These photos may be used without permission, although we request
              that you cite the original publication source. Some photographs, however,
              are copyrighted and were used with permission; to reuse these photos, you
              must receive permission from the copyright holder. Such restricted photos
              are noted when "Reproduced with permission from…" or "Courtesy of…" appear
              in the figure legends.
              <br />
              We can supply electronic copies of most but not all unrestricted photos from
              our books (some images from older books may be missing); please use this
              form to request specific photos.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
